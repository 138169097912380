import {useEffect} from 'react';
import {matchPath, useLocation} from 'react-router-dom';
import {useMatomo} from '@datapunt/matomo-tracker-react'
import {Contract} from "../types";
import {config} from "../config";
import {AppUrls, AppUrlsList, TrackingService} from "../constants";

export function useMatomoTracker(contract: Contract) {
    const { pathname } = useLocation();
    const { trackPageView } = useMatomo();

    const match = matchPath(pathname, {
        path: AppUrlsList,
        exact: true,
        strict: false
    });

    let dimensions: { id: number; value: string; }[] = [];
    let contractDimensionId = parseInt(config.matomoDimensionContractId as string);
    let serviceDimensionId = parseInt(config.matomoDimensionServiceId as string);
    let contractLabel = '';

    if ("undefined" !== typeof contract ) {
        contractLabel = contract.label;
        dimensions.push({
            id: contractDimensionId,
            value: contract.label,
        })
    }
    if (null !== match ) {
        let serviceDimensionValue = TrackingService.HOME;

        Object.entries(AppUrls).forEach(
            ([key, value]) => {
                if (match.path === value) {
                    serviceDimensionValue = [contractLabel, TrackingService[key]].join(' : ');
                }
            }
        );
        dimensions.push({
            id: serviceDimensionId,
            value: serviceDimensionValue,
        })
    }
    useEffect(() => {
        if(contract) {
            trackPageView({
                customDimensions: dimensions, // optional
            })
        }
    }, [pathname,contract?.label]);
}
