import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { SzIcon, SzModal, SzPagination, SzTable } from "@suezenv/react-theme-components";
import { Area, Organization, Pagination } from "../../../../types";
import { AppAdminDetailsUrls, Constants } from "../../../../constants";
import { useLocation, useParams } from "react-router-dom";
import { CommonHelper } from "../../../../helper/Common";
import TabHeader from "../../adminTabs/tabHeader";
import AdminOrganizationAddArea from "./AdminOrganizationAddArea";
import { OrganizationService } from "../../../../services/OrganizationService";
import { useQuery, useQueryClient } from "react-query";
import {AreaService} from "../../../../services/AreaService";
import ConfirmationModal from "../../../elements/ConfirmationModal";

type AreaData = {
    actions: JSX.Element,
    exploitant: string,
    label: string,
    organization: string,
    skillSet: string,
}

const AdminOrganizationAreas = (props: { organizationId: string, readonly?: boolean }) => {
    const history = useHistory();
    const location = useLocation();
    const { contractId } = useParams();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { organizationId, readonly } = props;
    const [areasData, setAreasData] = useState<AreaData[]>([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pagination, setPagination] = useState<Pagination>();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [areaToDeleteLabel, setAreaToDeleteLabel] = useState<string>("");
    const [areaToDeleteId, setAreaToDeleteId] = useState<string>("");

    const fetchOrganization = () => OrganizationService.getOrganization(organizationId);
    const {
        data: organization,
    } = useQuery<Organization>(['organizationAreas', organizationId], fetchOrganization);

    const fetchOrganizationAreas = () => OrganizationService.getOrganizationAreas(organizationId, currentPage, Constants.DEFAULT_PER_PAGE);
    const {
        data: organizationAreas,
        isSuccess
    } = useQuery(['organizationAreas', organizationId, currentPage], fetchOrganizationAreas);

    const onAddFormValidate = () => {
        queryClient.invalidateQueries(['organizationAreas', organizationId]);
        queryClient.invalidateQueries(['allOperatorAreas', organizationId]);
        queryClient.invalidateQueries(['allOrganizationAreas']);
    }

    const deleteOrganizationAreaHandler = () => {
        setShowConfirmationModal(false);
        // remove user from heimdall
        OrganizationService.deleteContractOrganizationArea(organizationId, areaToDeleteId)
            .then(hideDeleteAreaConfirmationModal)
            .then(() => {
                OrganizationService.getOrganizationAreas(organizationId, currentPage, Constants.DEFAULT_PER_PAGE).then((organizationAreas:any)=>{
                    const areas: Area[] = organizationAreas?.data;
                    let areasData: AreaData[] = [];
                    areas.forEach((area: Area) => areasData.push(formatData(area)));
                    setAreasData(areasData);
                    setPagination(CommonHelper.getPagination(areas.length, organizationAreas?.headers));
                });
            })
        ;
    }

    const showDeleteAreaConfirmationModal = (areaId: string, areaName: string) => {
        setAreaToDeleteId(areaId);
        setAreaToDeleteLabel(areaName);
        setShowConfirmationModal(true);
    }

    const hideDeleteAreaConfirmationModal = () => {
        setShowConfirmationModal(false);
    }

    useEffect(() => {
        if (isSuccess) {
            const areas: Area[] = organizationAreas?.data;
            let areasData: AreaData[] = [];
            areas.forEach((area: Area) => areasData.push(formatData(area)));
            setAreasData(areasData);
            setPagination(CommonHelper.getPagination(areas.length, organizationAreas?.headers));
        }
    }, [organizationAreas]);

    const defaultSorted: any = [
        {
            dataField: 'name',
            order: 'desc'
        }
    ];

    const columns = [
        {
            dataField: 'label',
            text: t('admin.area.label')
        },
        {
            dataField: 'skillSet',
            text: t('admin.area.skillSet')
        },
        {
            dataField: 'exploitant',
            text: t('admin.area.exploitant')
        },
        {
            dataField: 'organization',
            text: t('admin.area.organization')
        }
    ];

    if (!readonly) {
        columns.push(
            {
                dataField: 'actions',
                text: ''
            })
    }

    const goToContractAreaDetails = (contractId: string, areaId: string) => {
        history.push(CommonHelper.generateAdminDetailsSubRoute(location.pathname, AppAdminDetailsUrls.AREA_DETAILS)
            .replace(':contractId', contractId)
            .replace(':areaId', areaId)
        );
    }

    const formatData = (area: Area): AreaData => {
        return {
            label: area.name ?? (area.label ?? ""),
            exploitant: "0",
            organization: "0",
            skillSet: "0",
            actions: <div className="text-right"> <SzIcon variant='line' icon='task-list-text-1' className="color-primary"
                             onClick={() => goToContractAreaDetails(contractId, area.id)}/>
                <SzIcon variant='line' icon='bin-2-alternate' className="color-danger" onClick={() => {
                    showDeleteAreaConfirmationModal(area.id, area.name ?? (area.label ?? ""));
                }}/>
            </div>

        }
    };

    const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber);

    const renderPagination = (pagination: Pagination) => {
        return <div className="d-flex justify-content-center my-4">
            <SzPagination
                totalItemsCount={pagination.totalCount}
                activePage={currentPage}
                onChange={handlePageChange}
                itemsCountPerPage={pagination.perPage}
                pageRangeDisplayed={+Constants.USERS_PAGINATION_PAGE_RANGE}
            />
        </div>;
    }

    return <div className="box">
        <TabHeader title={t('admin.tabs.areas')}
                   subtitle={t("admin.tabs.areasSubtitle", { count: pagination?.totalCount })}
                   onBtnClick={ !readonly ? () => setShowAddModal(true) : undefined}/>
        {areasData && <SzTable
            data={areasData}
            keyField={'label'}
            columns={columns}
            defaultSorted={defaultSorted}
        />}
        <SzModal
            show={showAddModal}
            handleClose={() => setShowAddModal(false)}
            size={"xl"}
            centered={true}
            title={t('admin.area.modal.add.title')}
        >
            {organization &&
            <AdminOrganizationAddArea
                organization={organization}
                refetchAreas={onAddFormValidate}
            />
            }
        </SzModal>
        <ConfirmationModal
            show={showConfirmationModal}
            onValidate={deleteOrganizationAreaHandler}
            onCancel={hideDeleteAreaConfirmationModal}
            displayCancelBtn={true}
            modalBody={t('admin.area.modal.delete.body', {
                id: areaToDeleteId,
                label: areaToDeleteLabel
            })}
            modalTitle={t('admin.area.modal.delete.title')}
        />

        {pagination && renderPagination(pagination)}
    </div>
}

export default AdminOrganizationAreas;
