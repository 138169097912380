import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Contract, ContractService, Pagination } from "../../../types";
import './specificServices.scss';
import { SzAlert, SzIcon, SzModal, SzPagination, SzSpinner, SzTable } from "@suezenv/react-theme-components";
import TabHeader from "../adminTabs/tabHeader";
import AdminServiceAdd from "./AdminServiceAdd";
import { useTranslation } from "react-i18next";
import { Constants } from "../../../constants";
import { useQuery, useQueryClient } from "react-query";
import { ContractServiceService } from "../../../services/ContractServiceService";
import { CommonHelper } from "../../../helper/Common";
import ConfirmationModal from "../../elements/ConfirmationModal";

type ServiceData = {
    actions: JSX.Element,
    label: string,
    skillSet: string,
}

const AdminServices = (props: { currentContract: Contract, contractSelected?: Contract, readonly?: boolean }) => {
    const { currentContract, contractSelected, readonly } = props;
    const { t } = useTranslation();
    const usedContract = contractSelected ?? currentContract;
    const [servicesData, setServicesData] = useState<ServiceData[]>();
    const [showAddModal, setShowAddModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pagination, setPagination] = useState<Pagination>();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [contractServiceToDeleteId, setContractServiceToDeleteId] = useState<string>("");
    const [serviceToDeleteName, setServiceToDeleteName] = useState<string>("");
    const [queryFilter, setQueryFilter] = useState<string>("");

    const queryClient = useQueryClient();

    const refreshServicesAndContractServices = () => {
        queryClient.invalidateQueries(['contractServices', usedContract.id]);
        queryClient.invalidateQueries("services");
    }

    const fetchContractServiceByName = () => ContractServiceService.getContractServiceByName(usedContract.id, currentPage, queryFilter);

    const {
        data,
        isError,
        isLoading,
        isSuccess
    } = useQuery(['contractServices', usedContract.id, queryFilter, currentPage], fetchContractServiceByName);

    useEffect(() => {
        if (isSuccess) {
            const contractServices: ContractService[] = data?.data
            let ServiceData: ServiceData[] = [];
            contractServices.forEach((contractService: ContractService) => ServiceData.push(formatData(contractService)));
            setServicesData(ServiceData);
            setPagination(CommonHelper.getPagination(contractServices.length, data?.headers));
        }
    }, [data]);

    const deleteContractService = () => {
        setShowConfirmationModal(false);
        ContractServiceService.deleteContractService(contractServiceToDeleteId)
            .then(refreshServicesAndContractServices)
            .then(hideDeleteContractServiceConfirmationModal);
    }

    const showDeleteConfirmationModal = (contractService: ContractService) => {
        setContractServiceToDeleteId(contractService.id);
        setServiceToDeleteName(contractService.service.label);
        setShowConfirmationModal(true);
    }

    const hideDeleteContractServiceConfirmationModal = () => {
        setShowConfirmationModal(false);
        setContractServiceToDeleteId("");
        setServiceToDeleteName("");
    }

    const defaultSorted: any = [
        {
            dataField: 'name',
            order: 'desc'
        }
    ];

    const columns = [
        {
            dataField: 'label',
            text: t('admin.services.name')
        },
        {
            dataField: 'skillSet',
            text: t('admin.services.skillSet')
        }
    ];

    if (!readonly) {
        columns.push(
            {
                dataField: 'actions',
                text: ''
            })
    }

    const formatData = (contractService: ContractService): ServiceData => {
        return {
            label: contractService.service.label,
            skillSet: contractService.service.categories.length > 0 ? contractService.service.categories.length.toString() : t("admin.services.transversal"),
            actions: <div className={'text-right'}>
                <SzIcon variant='line' icon='task-list-text-1' className="color-primary pr-3"/>
                <SzIcon variant='line' icon='bin-2-alternate' className="color-danger pr-5"
                        onClick={() => showDeleteConfirmationModal(contractService)}/>
            </div>
        }
    };

    const renderPagination = (pagination: Pagination) => {
        return <div className="d-flex justify-content-center my-4">
            <SzPagination
                totalItemsCount={pagination.totalCount}
                activePage={currentPage}
                onChange={(pageNumber: number) => setCurrentPage(pageNumber)}
                itemsCountPerPage={pagination.perPage}
                pageRangeDisplayed={+Constants.CONTRACTS_PAGINATION_PAGE_RANGE}
            />
        </div>;
    }

    if (isError) {
        return <SzAlert
            variant="warning"
            transparent={true}
            className="w-100 border-0 flex-column p-4"
        >
            {t('alert.loading_error')}
        </SzAlert>
    }

    return <div className="box">
        <TabHeader title={t('admin.tabs.services')}
                   subtitle={t('admin.tabs.servicesSubtitle', { count: pagination?.totalCount })}
                   onBtnClick={ !readonly ? () => setShowAddModal(true) : undefined}
                   onSearch={setQueryFilter}
        />

        {isLoading && <div className='p-4 text-center'>
            <SzSpinner/>
        </div>}

        {!isLoading && servicesData && <SzTable
            data={servicesData}
            keyField={'label'}
            columns={columns}
            defaultSorted={defaultSorted}
        />}
        {!isLoading && pagination && renderPagination(pagination)}
        <SzModal
            show={showAddModal}
            handleClose={() => setShowAddModal(false)}
            size={"xl"}
            centered={true}
            title={t('admin.services.modal.add.title')}
        >
            <AdminServiceAdd
                currentContract={usedContract.id}
                refreshData={refreshServicesAndContractServices}
            />
        </SzModal>
        <ConfirmationModal
            show={showConfirmationModal}
            onValidate={deleteContractService}
            onCancel={hideDeleteContractServiceConfirmationModal}
            displayCancelBtn={true}
            modalBody={t('admin.services.modal.delete.bodyContract', {
                contract: usedContract.label,
                service: serviceToDeleteName
            })}
            modalTitle={t('admin.services.modal.delete.title')}
        />
    </div>
}

const mapStateToProps = (state: { contract: { currentContract: Contract } }) => ({
    currentContract: state.contract.currentContract
});

export default connect(mapStateToProps)(AdminServices);
