import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { connect } from "react-redux";

import {
    SzAlert,
    SzIcon,
    SzModal,
    SzPagination,
    SzSpinner,
    SzTable,
    SzTypographie
} from "@suezenv/react-theme-components";
import { AppAdminDetailsUrls, Constants, OrganizationTypes } from "../../../constants";
import {Contract, Organization, Pagination, User, UserHeimdall} from "../../../types";
import { CommonHelper } from "../../../helper/Common";
import { UsersService } from "../../../services/UsersService";
import TabHeader from "../adminTabs/tabHeader";
import AdminUserAdd from "../organizations/organizationUsers/AdminUserAdd";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import ConfirmationModal from "../../elements/ConfirmationModal";
import {OrganizationService} from "../../../services/OrganizationService";
import userOrganizations from "./userOrganizations";

const AdminUsers = (props: { contractSelected?: Contract, currentContract: Contract, readonly?: boolean }) => {
    type UserData = {
        id: string,
        actions: JSX.Element,
        username: string
        name: JSX.Element
        organization: string | JSX.Element
        operator: string | JSX.Element
    }

    const queryClient = useQueryClient();

    const { currentContract, contractSelected, readonly } = props;
    const usedContract = contractSelected ?? currentContract;
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(1);
    const [UsersData, setUsersData] = useState<UserData[]>();
    const [showAddModal, setShowAddModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [userToDeleteName, setUserToDeleteName] = useState<string>("");
    const [userToDeleteId, setUserToDeleteId] = useState<string>("");
    const [queryFilter, setQueryFilter] = useState<string>("");

    const fetchUsers = () => UsersService.getUsers(usedContract, currentPage, Constants.DEFAULT_PER_PAGE, queryFilter);

    const {
        data: users,
        isError,
        isLoading,
        isSuccess
    } = useQuery(["users", usedContract.id, queryFilter, currentPage], fetchUsers);
    const [pagination, setPagination] = useState<Pagination>();

    useEffect(() => {
        if (isSuccess && users?.data) {
            let UsersData: UserData[] = [];
            users.data.forEach((user: UserHeimdall) => UsersData.push(formatUserData(user)));
            setUsersData(UsersData);
            setPagination(CommonHelper.getPagination(users.data.length, users?.headers));
        }
    }, [users]);

    const fetchUsersNbOrganizations = () => UsersService.getUsersNbOrganizations(
        users?.data.map((user: User) => user.id),
        usedContract.id,
        1,
        9999,
        OrganizationTypes.COMMUNITY
    );
    const fetchUsersNbOperator = () => UsersService.getUsersNbOrganizations(
        users?.data.map((user: User) => user.id),
        usedContract.id,
        1,
        9999,
        OrganizationTypes.OPERATOR
    );

    const {
        data: nbOrganizations,
        isError: nbOrganizationsError
    } = useQuery(["usersNbOrganizations", usedContract.id, currentPage], fetchUsersNbOrganizations, { enabled: isSuccess });
    const {
        data: nbOperators,
        isError: nbOperatorsError
    } = useQuery(["usersNbOperators", usedContract.id, currentPage], fetchUsersNbOperator, { enabled: isSuccess });

    useEffect(() => {
        if (UsersData) {
            let tmpUsersData = UsersData?.slice(0);
            tmpUsersData = tmpUsersData.map((userData: UserData) => {
                return {
                    ...userData,
                    operator: nbOperators?.data ? CommonHelper.findEntityCount(Object.entries(nbOperators?.data), nbOperatorsError, userData.id) :
                        <SzSpinner/>,
                    organization: nbOrganizations?.data ? CommonHelper.findEntityCount(Object.entries(nbOrganizations?.data), nbOrganizationsError, userData.id) :
                        <SzSpinner/>
                };
            });
            setUsersData(tmpUsersData);
        }
    }, [nbOrganizations, nbOrganizationsError, nbOperators, nbOperatorsError]);

    const defaultSorted: any = [
        {
            dataField: 'name',
            order: 'desc'
        }
    ];

    const columns = [
        {
            dataField: 'name',
            text: t('admin.users.name')
        },
        {
            dataField: 'organization',
            text: t('admin.users.organization')
        },
        {
            dataField: 'operator',
            text: t('admin.users.operator')
        }
    ];

    if (!readonly) {
        columns.push(
            {
                dataField: 'actions',
                text: ''
            })
    }


    const goToUserDetails = (contractId: string, userId: string) => {
        history.push(CommonHelper.generateAdminDetailsSubRoute(location.pathname, AppAdminDetailsUrls.USER_DETAILS)
            .replace(':contractId', contractId)
            .replace(':userId', userId)
        );
    }

    const showDeleteConfirmationModal = (userId: string, userName: string) => {

        setUserToDeleteId(userId);
        setUserToDeleteName(userName);
        setShowConfirmationModal(true);
    }

    const deleteContractOrganizationUserHandler = () => {
        setShowConfirmationModal(false);
        // remove user from heimdall
        UsersService.deleteContractOrganizationUser(usedContract.id, userToDeleteId)
            .then(hideDeleteUserConfirmationModal)
            .then(() => {
               UsersService.getUsers(usedContract, currentPage, Constants.DEFAULT_PER_PAGE)
                   .then((result) => {
                       const formattedUsers = result.data.map((user: UserHeimdall) =>
                           formatUserData(user)
                       );
                       setUsersData(formattedUsers)
                   });
               ;
            })
        ;
    }

    const hideDeleteUserConfirmationModal = () => {
        setShowConfirmationModal(false);
    }

    const formatUserData = (user: UserHeimdall): UserData => {
        return {
            id: user.id,
            username: user.username,
            name: <>
                <SzTypographie weight="bold" className="color-primary mb-0">{user.firstName} {user.lastName}</SzTypographie>
                <SzTypographie variant="caption">{user.username}</SzTypographie>
            </>,
            organization: nbOrganizations?.data ? CommonHelper.findEntityCount(Object.entries(nbOrganizations?.data), nbOrganizationsError, user.id) :
                <SzSpinner/>,
            operator: nbOperators?.data ? CommonHelper.findEntityCount(Object.entries(nbOperators?.data), nbOperatorsError, user.id) :
                <SzSpinner/>,
            actions: <div className={'text-right'}>
                <SzIcon variant='line' icon='task-list-text-1' className="color-primary m-left" onClick={() => {
                                goToUserDetails(usedContract.id, user.id)
                            }}/>

                <SzIcon variant='line' icon='bin-2-alternate' className="color-danger" onClick={() => {
                    let username = `${user.firstName} ${user.lastName}`;
                    showDeleteConfirmationModal(user.id, username);
                }}/>
            </div>
        };
    };

    const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber);

    const renderPagination = (pagination: Pagination) => {
        return <div className="d-flex justify-content-center my-4">
            <SzPagination
                totalItemsCount={pagination.totalCount}
                activePage={currentPage}
                onChange={handlePageChange}
                itemsCountPerPage={pagination.perPage}
                pageRangeDisplayed={+Constants.USERS_PAGINATION_PAGE_RANGE}
            />
        </div>;
    }

    const onUserAdd = () => {
        queryClient.invalidateQueries(["users", usedContract.id]);
        queryClient.invalidateQueries(["usersNbOrganizations", usedContract.id]);
        queryClient.invalidateQueries(["usersNbOperators", usedContract.id]);
    }

    if (isError) {
        return <SzAlert
            variant="warning"
            transparent={true}
            className="w-100 border-0 flex-column p-4"
        >
            {t('alert.loading_error')}
        </SzAlert>
    }

    return <div className="box">
        <TabHeader title={t('admin.tabs.users')}
                   subtitle={t('admin.tabs.usersSubtitle', { count: pagination?.totalCount })}
                   onBtnClick={ !readonly ? () => setShowAddModal(true): undefined }
                   onSearch={setQueryFilter}
        />

        {isLoading && <div className='p-4 text-center'>
                <SzSpinner/>
            </div>
        }

        {!isLoading && UsersData && <SzTable
            data={UsersData}
            keyField={'username'}
            columns={columns}
            defaultSorted={defaultSorted}
        />}

        {!isLoading && pagination && renderPagination(pagination)}

        <SzModal
            show={showAddModal}
            handleClose={() => setShowAddModal(false)}
            size={"xl"}
            centered={true}
            title={t('admin.users.modal.add.title')}
        >
            <AdminUserAdd
                contractId={usedContract.id}
                refreshData={onUserAdd}
            />
        </SzModal>

        <ConfirmationModal
            show={showConfirmationModal}
            onValidate={deleteContractOrganizationUserHandler}
            onCancel={hideDeleteUserConfirmationModal}
            displayCancelBtn={true}
            modalBody={t('admin.contracts.user.modal.delete.body', {
                id: userToDeleteId,
                name: userToDeleteName
            })}
            modalTitle={t('admin.contracts.user.modal.delete.title')}
        />
    </div>
}

const mapStateToProps = (state: { contract: { currentContract: Contract } }) => ({
    currentContract: state.contract.currentContract
});

export default connect(mapStateToProps)(AdminUsers);
