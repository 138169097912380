import React, { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from "react-router";
import { SzIcon, SzModal } from "@suezenv/react-theme-components";
import { config } from "../../config";
import Header from '../elements/header';
import Loading from '../elements/loading';
import { AppUrls, Roles } from "../../constants";
import NavigationDashboard from '../navigationDashboard';
import LoadingPage from '../../pages/loadingPage';
import UserPreferencesModal from "../UserPreferencesModal";
import { ReactComponent as OpenDataLogo } from "../../assets/icon/open_data.svg";
import { ReactComponent as SignalLogo } from "../../assets/icon/alert-diamond.svg";
import { ReactComponent as WifiMonitorLogo } from "../../assets/icon/wifi-monitor.svg";
import { RightsHelper } from "../../helper/RightsHelper";
import { useTheme } from "../../hooks/theme";
import SzNewSideMenu from "../elements/SzNewSideMenu";
import { useMatomoTracker } from "../../hooks/useMatomoTracker";
import './navigation.scss';
import { Contract } from "../../types";

type INavigationProps = RouteComponentProps & {
    children: ReactNode;
    isError: boolean;
    loading: Boolean;
    logged: Boolean;
    user: any;
    contract: Contract;
}

export type Item = {
    className?: string,
    key: string,
    title: string,
    icon: JSX.Element,
    handleClick: () => void,
    category: string,
    active?: boolean,
    type: 'item' | 'submenu' | 'external_link'
}

export type MenuItem = {
    roles?: string[],
    item: Item
}

const Navigation: React.FunctionComponent<INavigationProps> = (props) => {
    const { children, user, location, history, contract } = props;
    const { t } = useTranslation();
    const [showOperatorMenu, setShowOperatorMenu] = useState<boolean>(false);
    const [showMenu, setShowMenu] = useState<boolean>(true);
    const [modalShow, setModalShow] = useState(false);
    const [menu, setMenu] = useState<Item[]>([]);
    const { appTheme } = useTheme()!;
    useMatomoTracker(contract);

    const closeMenuHandler = () => {
        setShowOperatorMenu(false);
    }

    function useCloseDashboardMenu(ref: MutableRefObject<any>) {
        useEffect(() => {
            function handleCLick(event: any) {
                if (ref.current
                    && !ref.current.contains(event.target)
                    && !event.target.closest(".nav-dashboard-modules")
                    && !event.target.closest(".nav-dashboard-module-dropdown-button")
                ) {
                    setShowOperatorMenu(false);
                }
            }

            // Bind the event listener
            document.addEventListener("mouseup", handleCLick);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mouseup", handleCLick);
            };
        }, [ref]);
    }

    const locationHasOneOfUrl = (urls: string[]): boolean => {
        let found: boolean = false
        urls.forEach((url: string) => {
            if (location.pathname === AppUrls.HOME && url === AppUrls.HOME) {
                found = true;
            } else if (location.pathname.includes(url) && url !== AppUrls.HOME) {
                found = true;
            }
        });
        return found;
    }
    
    const initMenu = () => {
        const menuItems: MenuItem[] = [
            {
                item: {
                    key: 'dashboard',
                    icon: <SzIcon icon="layout-dashboard" variant={"line"}/>,
                    title: t('navigation.dashboard'),
                    handleClick: () => history.push(AppUrls.HOME),
                    type: 'item',
                    active: locationHasOneOfUrl([AppUrls.HOME, AppUrls.CARTO]),
                    category: 'hypervision'
                }
            },
            {
                roles: [Roles.ROLE_POWERBI_REPORT_TRENDS],
                item: {
                    key: 'powerBi',
                    icon: <SzIcon icon="analytics-bars-3d" variant={"line"}/>,
                    title: t('navigation.Reporting'),
                    handleClick: () => history.push(AppUrls.POWER_BI_WORKSPACE),
                    type: 'item',
                    active: locationHasOneOfUrl([AppUrls.POWER_BI_WORKSPACE]),
                    category: 'hypervision'
                }
            },
            {
                roles: [Roles.ROLE_THINGS_BOARD],
                item: {
                    key: 'thingsboardDashboard',
                    icon: <SzIcon icon="analytics-bars-3d" variant={"line"}/>,
                    title: t('navigation.thingsboard.dashboard'),
                    handleClick: () => history.push(AppUrls.THINGS_BOARD_WORKSPACE),
                    type: 'item',
                    active: locationHasOneOfUrl([AppUrls.THINGS_BOARD_WORKSPACE]),
                    category: 'hypervision'
                }
            },
            {
                roles: [Roles.ROLE_SIGNAL_NOTIFY, Roles.ROLE_SIGNAL_OPERATOR],
                item: {
                    key: "signal",
                    icon: <div style={{ fontSize: "1.5rem", lineHeight: "1.5" }}><SignalLogo
                        className="icon-big color-icon-primary"/></div>,
                    title: t('navigation.signal'),
                    handleClick: () => history.push(AppUrls.SIGNAL_VIEW),
                    type: 'item',
                    active: locationHasOneOfUrl([AppUrls.SIGNAL_DETAIL, AppUrls.SIGNAL_VIEW, AppUrls.SIGNAL_CREATE]),
                    category: 'tools'
                }
            },
            {
                roles: [Roles.ROLE_REQUEST, Roles.ROLE_REQUEST_OPERATOR],
                item: {
                    key: "request",
                    icon: <SzIcon icon="messages-bubble-edit" variant="line"/>,
                    title: t('navigation.request'),
                    handleClick: () => history.push(AppUrls.REQUEST_VIEW),
                    type: 'item',
                    active: locationHasOneOfUrl([AppUrls.REQUEST_VIEW, AppUrls.REQUEST_CREATE, AppUrls.REQUEST_CHOICE]),
                    category: 'tools'
                }
            },
            {
                roles: [Roles.ROLE_PUBLIC_SPACE_PLANNER],
                item: {
                    key: "publicWork",
                    icon: <SzIcon icon="professions-man-construction-1" variant="line"/>,
                    title: t('navigation.PublicWork'),
                    handleClick: () => history.push(AppUrls.PUBLIC_WORK_LIST),
                    type: 'item',
                    active: locationHasOneOfUrl([AppUrls.PUBLIC_WORK_LIST,AppUrls.PUBLIC_WORK_NEW, AppUrls.PUBLIC_WORK_DETAIL]),
                    category: 'tools'
                }
            },
            {
                roles: [Roles.ROLE_VA_GEST_DOC],
                item: {
                    key: "document",
                    icon: <SzIcon icon="folder-file-1" variant="line"/>,
                    title: t('navigation.document'),
                    handleClick: () => {
                        const portalMayan = window.open(`${config.mayanUrl}/cabinets/cabinets`, '_blank');
                        portalMayan?.focus();
                    },
                    type: 'external_link',
                    category: 'tools'
                }
            },
            {
                roles: [Roles.ROLE_SUPER_ADMIN_PORTAL_OPEN_API, Roles.ROLE_PORTAL_VDM],
                item: {
                    key: "openApi",
                    icon: <div style={{ fontSize: "1.5rem", lineHeight: "1.5" }}><OpenDataLogo
                        className="icon-big color-icon-primary"/></div>,
                    title: t('navigation.openApi'),
                    handleClick: () => {
                        const portalOpenApiWindow = window.open(config.portalOpenApiUrl, '_blank');
                        portalOpenApiWindow?.focus();
                    },
                    type: 'external_link',
                    category: 'tools'
                }
            },
            {
                roles: [Roles.ROLE_SUPER_ADMIN_PORTAL_THINGS_BOARD, Roles.ROLE_THINGS_BOARD],
                item: {
                    key: "thingsboardPortal",
                    icon: <div style={{ fontSize: "1.5rem", lineHeight: "1.5" }}><WifiMonitorLogo
                        className="icon-big"/></div>,
                    title: t('navigation.thingsboard.portal'),
                    handleClick: () => {
                        const portalThingsboardWindow = window.open(config.portalThingsboardUrl, '_blank');
                        portalThingsboardWindow?.focus();
                    },
                    type: 'external_link',
                    category: 'tools'
                }
            },
            {
                roles: [Roles.ROLE_SUPER_ADMIN_PORTAL_VILLAGILE],
                item: {
                    key: "contractManagement",
                    icon: <SzIcon icon="navigation-menu-4" variant="line"/>,
                    title: t('navigation.contractManagement'),
                    handleClick: () => history.push(AppUrls.ADMIN_CONTRACTS),
                    type: 'item',
                    active: locationHasOneOfUrl([AppUrls.ADMIN_CONTRACTS]),
                    category: 'administration'
                }
            },
            {
                roles: [Roles.ROLE_SUPER_ADMIN_CONTRACT, Roles.ROLE_SUPER_ADMIN_ORGANIZATION],
                item: {
                    key: "territorialManagement",
                    icon: <SzIcon icon="single-neutral-actions-setting" variant="line"/>,
                    title: t('navigation.territorialManagement'),
                    handleClick: () => history.push(AppUrls.ADMIN_GESTION_TERRITORIALE),
                    type: 'item',
                    active: locationHasOneOfUrl([AppUrls.ADMIN_GESTION_TERRITORIALE]),
                    category: 'administration'
                }
            },
            {
                item: {
                    className: "nav-dashboard-module-dropdown-button",
                    key: "portals",
                    icon: <SzIcon icon="money-wallet-open" variant="line"/>,
                    title: t('navigation.portals'),
                    handleClick: () => setShowOperatorMenu(!showOperatorMenu),
                    type: 'submenu',
                    active: showOperatorMenu,
                    category: 'hypervision'
                }
            }
        ];

        return menuItems
            .filter(menuItem => {
                return !menuItem.roles || (menuItem.roles && RightsHelper.isGranted(user, menuItem.roles));
            })
            .map(menuItem => menuItem.item);
    };

    useEffect(() => {
        setMenu(initMenu());
    }, [user, location.pathname, showOperatorMenu]);

    const wrapperRef = useRef(null);
    useCloseDashboardMenu(wrapperRef);

    return props.logged || props.isError ? (
        <>
            <Header
                isError={props.isError}
                setModalShow={setModalShow}
                showMenu={showMenu}
                setShowMenu={setShowMenu}
            />
            <div className="d-flex flex-row flex-fill main-wrapper">
                <SzNewSideMenu items={menu} setShowMenu={setShowMenu} showMenu={showMenu}/>
                <main className="flex-grow-1 d-flex flex-column">
                    {props.loading && <Loading/>}
                    <div className="flex-fill position-relative no-scroll h-100" ref={wrapperRef}>
                        {showOperatorMenu &&
                            (
                                <NavigationDashboard
                                    closeMenuHandler={closeMenuHandler}
                                    user={user}
                                />
                            )
                        }
                        {children}
                    </div>
                    <SzModal
                        className={"modal-centered"}
                        title={t('settings.dashboard')}
                        show={modalShow}
                        size="xl"
                        backdrop={true}
                        centered={true}
                        handleClose={() => {
                            setModalShow(false)
                        }}
                    >
                        <UserPreferencesModal/>
                    </SzModal>
                </main>
            </div>
        </>
    ) : (
        <LoadingPage/>
    );
};

export default withRouter(Navigation);
