import { login, logout } from '../actions';
import { ApiUrls, AppUrls } from '../constants';
import qs from "qs";
import axiosService from './Request';
import { store } from '../store';
import { config } from "../config";
import { Contract } from "../types";
import { ContractsService } from "./ContractsService";
import { PermissionsService } from "./PermissionsService";
import { SkillSetService } from "./SkillSetService";
import { ThemeService } from "./ThemeService";
import { AxiosResponse } from "axios";

export class AuthenticateService {
    public static login() {
        const { token } = qs.parse(window.location.search.substring(1));
        if (!token) {
            localStorage.setItem("redirect-portal-extranet", window.location.href);
            window.location = AppUrls.SAML_LOGIN as any;
        } else {
            this.authenticate();
            localStorage.setItem(config.tokenKey, token.toString());
            let redirectHref = localStorage.getItem("redirect-portal-extranet");
            localStorage.removeItem("redirect-portal-extranet");
            window.location = redirectHref ? redirectHref as any : AppUrls.HOME;
        }
    }

    public static logout() {
        localStorage.clear();
        store.dispatch(logout());
        window.location = AppUrls.SAML_LOGOUT as any;
    }

    public static authenticate() {
        axiosService.get(ApiUrls.TOKEN_INFO_URL, false)
            .then(response => store.dispatch(login(response.data)));

        ContractsService.getContractsByUser()
            .then((response: AxiosResponse<Contract[]>) => response.data)
            .then((contracts: Contract[]) => {
                PermissionsService.setPermissions(contracts);
                if (contracts.length > 0) {
                    const firstContract = contracts.slice().shift();
                    if (firstContract) {
                        ThemeService.setTheme(firstContract);
                        ContractsService.setCurrentContract(firstContract);
                    }
                }
                SkillSetService.initUserSkills();
            });
    }
}
