import React, {useEffect,useState} from 'react';
import {PublicWorkDetail} from '@suezenv/public-space-planner-widget';
import '@suezenv/public-space-planner-widget/dist/index.css';
import {connect} from "react-redux";
import 'leaflet/dist/leaflet.css';
import {AppUrls,Services} from "../../constants";
import {useHistory, useParams} from "react-router";
import {UserHelper} from "../../helper/User";
import {config} from "../../config";
import {Contract, UserState} from "../../types";
import {OrganizationService} from "../../services/OrganizationService";
import {AreaService} from "../../services/AreaService";
import {useQuery} from "react-query";
import {PublicSpacePlannerService} from "../../services/PublicSpacePlannerService";



type IPublicWorkDetails = {
    user: UserState,
    currentContract: Contract,
}
type GeoServer = {
    url: string,
    workspace: string,
    layer: string,
    authkey: string,
}

const PublicWorkDetails = (props: IPublicWorkDetails) => {
    const history = useHistory();
    const [geoserver, setGeoserver] = useState<GeoServer>();
    const { currentContract, user } = props;
    const {heimdallId} = user.info;
    const {workId} = useParams();
    const publicSpacePlannerUser = PublicSpacePlannerService.transTypeUser(user);



    useEffect(() => {
        if (!currentContract) return;
        const organizationId = UserHelper.getServiceOrganization(user, Services.PUBLIC_SPACE_PLANNER);
        setGeoserver({
            authkey: config.geoserverAuthKey as string,
            url: config.geoserverUrl as string,
            layer: `UUID-${organizationId}`,
            workspace: `UUID-${currentContract.id}`,
        });
    }, [currentContract]);



    const fetchOrganizationByUser = () => OrganizationService.getOrganizationsByUserId(heimdallId);
    const {
        data: userOrganizations,
        isError,
        isLoading,
        isSuccess
    } = useQuery(['userOrganizations', heimdallId], fetchOrganizationByUser);

    const fetchUserGeoShape = () => AreaService.getUserGeoShape(user, {
        serviceCode: Services.SIGNAL_NOTIFY,
        merged: true
    });
    const {
        data: userGeoShape,
    } = useQuery<GeoJSON.Feature>(['userGeoShape', user.info.id], fetchUserGeoShape);

    const goToHomeHandle = () => {
        history.push(AppUrls.PUBLIC_WORK_LIST)
    }

    const editHandle = ( publicWorkId:string)=> {
        history.push(AppUrls.PUBLIC_WORK_EDIT.replace(':workId', publicWorkId))
    };

    return <div className="w-100 h-100 overflow-auto">
        {workId && // @ts-ignore
        <PublicWorkDetail editHandle={editHandle} workId={workId} goToHome={goToHomeHandle}  geoserver={geoserver} geoShape={userGeoShape||undefined} user={publicSpacePlannerUser}   />
        }
    </div>
};

const mapStateToProps = (state: { contract: { currentContract: Contract }, user: UserState }) => ({
    user: state.user,
    currentContract: state.contract.currentContract,
});
export default connect(mapStateToProps)(PublicWorkDetails);
