import axiosService from './Request';
import { ApiUrls } from '../constants';
import { Contract } from "../types";
import { UserWithRole } from "../components/admin/organizations/organizationUsers/AdminUserAdd";

export class UsersService {
    public static getUser(userId: string, contractId?: string) {
        const url = ApiUrls.USER.replace(":userId", userId)
        const body = {
            contractId
        }
        return axiosService.getWithParams(url, body, false, true)
            .then(response => response.data);
    }

    public static getUsers(contract: Contract, currentPage: number, itemsPerPage: number, userFilter: string) {
        const url = ApiUrls.USERS.replace(":contractId", contract.id)
        const body = {
            currentPage,
            itemsPerPage,
            userFilter,
        }

        return axiosService.getWithParams(url, body, false, true);
    }

    public static createUsersWithRoles(contractId: string, organizationIds: string[], usersRole: UserWithRole[]) {
        const url = ApiUrls.USERS_WITH_ROLES.replace(":contractId", contractId);
        const body: any = {
            organizations: organizationIds,
            users : []
        };

        usersRole.forEach((userRole: UserWithRole, key) => {
            body.users[key] = {
                email: userRole.userEmail,
                roles: [userRole.role]
            };
        });

       return axiosService.post(url, [body]);
    }

    public static getUsersNbOrganizations(usersIds: string[], contractId: string, currentPage: number, itemsPerPage: number, organizationType?: string) {
        const url = ApiUrls.USERS_NB_ORGANIZATION.replace(':contractId', contractId);
        const body = {
            users: usersIds,
            countOnly: true,
            organizationType: organizationType,
            currentPage: currentPage,
            itemsPerPage: itemsPerPage
        }

        return axiosService.getWithParams(url, body);
    }

    public static deleteOrganizationUser(organizationId: string, userId: string) {
        const url = ApiUrls.ORGANIZATION_USER
            .replace(":userId", userId)
            .replace(":organizationId", organizationId);
        return axiosService.delete(url, [], false, true);
    }

    public static deleteContractOrganizationUser(contractId: string, userId: string) {
        const url = ApiUrls.CONTRACT_ORGANIZATION_USER
            .replace(":userId", userId)
            .replace(":contractId", contractId);
        return axiosService.delete(url, [], false, true);
    }
}
