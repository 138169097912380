import { UserState } from '../types';

export class RightsHelper {
    public static isGranted(user: UserState, serviceRoles: string[]): boolean {
        const { roles } = user.info;

        if (roles === undefined) {
            return false;
        }

        let intersection = roles.filter(x => serviceRoles.includes(x));

        return intersection.length > 0;
    }
}
