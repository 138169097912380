import React from "react";
import { ServiceContract, UserState } from "../types";

export class UserHelper {
    public static getServiceOrganization(user: UserState, serviceName: string): string {
        const services = user.info.services[serviceName];
        const service = services.filter((serviceContract: ServiceContract) => 'organization' === serviceContract.type);
        return 1 === service.length ? service[0].id : '';
    }
}
